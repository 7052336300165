import { useAsyncCallback } from '@orus.eu/pharaoh'
import { useNavigate, useParams } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { memo, useCallback, useState } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { NotFound } from '../../../organisms/not-found'
import { UserPageContent } from '../common/backoffice-users/user-page-content'

const PlatformUserRoutingPage = memo(function PlatformUserRoutingPage() {
  const { userId } = useParams({ from: '/bak/users/$userId' })
  if (!userId) return <NotFound />
  return <PlatformUserPage userId={userId} />
})

export default PlatformUserRoutingPage

const PlatformUserPage = memo<{ userId: string }>(function PlatformUserPage({ userId }) {
  const [activityOriginTimestamp, setActivityOriginTimestamp] = useState<number | undefined>(oneYearAgoTimestamp)
  const navigate = useNavigate()

  const [user] = trpcReact.usersActivity.loadUserActivity.useSuspenseQuery({
    userId,
    fromTimestamp: activityOriginTimestamp,
  })

  const onLoadAllHistory = useCallback(() => setActivityOriginTimestamp(undefined), [])

  const customerId = user.customer ? user?.id : undefined
  const createSubscription = useAsyncCallback(async () => {
    if (!customerId) return
    const subscriptionId = await trpc.subscriptions.createSubscription.mutate({
      customerId,
    })
    void navigate({ to: '/bak/v2-pending-subscriptions/$subscriptionId', params: { subscriptionId } })
  }, [navigate, customerId])

  return (
    <UserPageContent
      type="platform"
      user={user}
      customerId={customerId}
      createSubscription={createSubscription}
      activityOriginTimestamp={activityOriginTimestamp}
      onLoadAllHistory={onLoadAllHistory}
    />
  )
})

const oneYearAgoTimestamp = DateTime.now().minus({ year: 1 }).toMillis()
